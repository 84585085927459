.book-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  p, h4 {
    max-width: 100%;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.book-card {
  margin: 10px 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid #ffc800;
  width: 310px;
  position: relative;

  .card-content {
    padding: 7px;
  }
}

.book-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.book-card .image {
  height: 265px;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 0.3s ease;
}

.book-card:hover img {
  transform: scale(1.15);
  cursor: pointer;
}

.book-card h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.book-card p {
  font-size: 13px;
  margin: 5px 0;
}

.book-card p:last-child {
  font-weight: bold;
}

.filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 10px;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #666;
}

.filter-bar div {
  display: flex;
  align-items: center;

  label {
    white-space: nowrap;
    padding-right: 10px;
  }
}
.buttons {
  .buttons-plc {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  button {
    width: 49%;
    text-align: center;

    &:last-of-type {
      color: white;
    }
  }
}

.content-loaders {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inside {
  z-index: 9;
  background: #ffc107;
  width: 140px;
  height: 140px;
  position: absolute;
  top: -70px;
  right: -70px;
  border-radius: 0 0 30px 30px;
  transition: all 0.3s, border-radius 2s, top 1s;
  overflow: hidden;

  .icon {
    position: absolute;
    right: 76px;
    top: 87px;
    color: white;
    opacity: 1;

    p {
      transform: rotate(45deg);
    }
  }
  &:hover {
    width: 100%;
    right: 0;
    top: 0;
    border-radius: 0;

    .icon {
      opacity: 0;
      right: 15px;
      top: 15px;
    }
    .contents {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .contents {
    padding: 5%;
    opacity: 0;
    transform: scale(0.5);
    transform: translateY(-200%);
    transition: opacity 0.2s, transform 0.8s;
    table {
      text-align: left;
      width: 100%;
    }
    h1,
    p,
    table {
      color: white;
    }
    p {
      font-size: 13px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .filter-bar  {
    padding: 10px 0;
  }

  .book-card {
    width: 256px;

    .image {
      height: 213px;
    }

    h4 {
      font-size: 12px;
      margin: 5px 0 10px 0;
    }

    .book-card p {
      font-size: 10px;
    }
  }

  .content-loader:last-of-type {
    display: none;
  }

  .filter-bar {
    label {
      display: none;
    }
  }
}

@media screen and (max-width: 1000px) {
  .content-loader:nth-last-of-type(2) {
    display: none;
  }

  .book-card .image {
    height: 175px;
  }

 .buttons-plc button {
    font-size: 12px;
    padding: 5px;
  }

  .book-card {
    width: 210px;

    h4 {
      font-size: 12px;
      margin: 5px 0 10px 0;
    }

    .book-card p {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .filter-bar {
    div {
      max-width: 50%;
      margin-bottom: 10px;
    }

    .buttons .buttons-plc {
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  .row.d-flex.align-items-end.w-100 {
    max-width: 100% !important;
    justify-content: space-between;
  }
}

@media screen and (max-width: 800px) {
  .book-card {
    width: 170px;

    .image {
      height: 142px;
    }
  }

  .book-cards p, .book-cards h4 {
    white-space: pre-line;
  }
}